<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
      spinner="spinner" />
    <v-layout class="mainfont" wrap justify-center>
      <v-flex xs11 pt-4>
        <v-layout wrap>
          <v-flex xs12>
            <v-layout wrap pt-7 pb-10>
            <v-flex xs12>
              <v-card class="pa-4">
                <v-layout wrap>
                  <v-flex xs6 pb-3 class="heading">
                    <span style="font-size: 20px">
                      Application Number: {{ list.applicationNo }}</span
                    >
                  </v-flex>
                  <v-flex xs6 pb-3 class="heading">
                    <span style="font-size: 20px">
                      Application Date: {{ formatDate(list.created_at) }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
            
            <v-card class="pb-4">
              <v-layout wrap>
                <v-flex xs12 pl-3 pt-3 pb-4 style="background-color: black">
                  <span class="heading" style="font-size: 25px">Nature Camp Details</span>
                </v-flex>

                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4 text-left pl-3>
                  <span class="subheading" style="font-size: 16px">
                    Camp Name
                  </span>
                  <br />
                  <span>{{ list.campid.campName }}</span>
                </v-flex>

                <v-flex xs4 text-left>
                  <span class="subheading" style="font-size: 16px"> Requested Date </span>
                  <br />
                  <span>
                    {{ formatDate(list.startDate) }}
                  </span>
                </v-flex>
                <v-flex xs4 text-left pb-3 >
                  <span class="subheading" style="font-size: 16px">
                    District Name
                  </span>
                  <br />
                  <span>{{ list.district }}</span>
                </v-flex>

                <v-flex  xs4 text-left pl-3>
                  <span class="subheading" style="font-size: 16px">
                    Duration of Camp
                  </span>
                  <br />
                  <span>{{ list.campDuration }}</span>&nbsp;
                  <span>Day</span>
                </v-flex>
                <!-- <v-flex xs12 text-left pt-7>
                                    <v-layout wrap justify-center>
                                        <v-flex xs8 text-center>
                                            <span class="subheading" style="font-size: 16px;">
                                                Selected camp at Pamba on
                                                <span style="color:red">
                                                    {{ formatDate(list.endDate) }}

                                                </span>
                                                can accommodate people as follows

                                            </span>
                                        </v-flex>

                                        <v-flex xs10 pt-3 pb-9>
                                            <v-card elevation="0" style="border: 1px solid black; border-radius: 0px;">
                                                <v-layout wrap pt-3 pb-3 justify-center>
                                                    <v-flex xs12>
                                                        <v-layout wrap justify-center>
                                                            <v-flex xs3 pt-2 text-center>
                                                                <span class="subheading">Staff/Group Leader</span>
                                                            </v-flex>
                                                            <v-flex pt-2 xs3 text-center>
                                                                <span class="subheading">Male Members</span>
                                                            </v-flex>
                                                            <v-flex pt-2 xs3 text-center>
                                                                <span class="subheading">Female Members</span>
                                                            </v-flex>
                                                            <v-flex pt-2 xs3 text-center>
                                                                <span class="subheading">Transgender Members</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                    <v-flex xs12 pt-3 pb-3>
                                                        <v-divider></v-divider>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                        <v-layout wrap justify-center>
                                                            <v-flex v-if="list" pb-2 xs3 text-center>
                                                                <span v-if="list.campid">
                                                                    <span v-if="list.campid.totalMembers">{{
                                                                        list.campid.totalMembers }}</span>

                                                                </span>

                                                            </v-flex>
                                                            <v-flex v-if="list" pb-2 xs3 text-center>


                                                                <span v-if="list.campid">
                                                                    <span v-if="list.campid.membersLimit">
                                                                        <span v-if="list.campid.membersLimit.male">
                                                                            {{ list.campid.membersLimit.male }}

                                                                        </span>

                                                                    </span>

                                                                </span>


                                                            </v-flex>
                                                            <v-flex v-if="list" pb-2 xs3 text-center>


                                                                <span v-if="list.campid">
                                                                    <span v-if="list.campid.membersLimit">
                                                                        <span v-if="list.campid.membersLimit.male">
                                                                            {{ list.campid.membersLimit.male }}

                                                                        </span>

                                                                    </span>

                                                                </span>


                                                            </v-flex>



                                                            <v-flex v-if="list" pb-2 xs3 text-center>


                                                                <span v-if="list.campid">
                                                                    <span v-if="list.campid.membersLimit">
                                                                        <span v-if="list.campid.membersLimit.transgender">
                                                                            {{ list.campid.membersLimit.transgender }}

                                                                        </span>

                                                                    </span>

                                                                </span>


                                                            </v-flex>

                                                        </v-layout>
                                                    </v-flex>
                                                </v-layout>
                                            </v-card>
                                        </v-flex>
                                    </v-layout>


                                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-7>
          <v-flex xs12>
            <v-card>
              <v-layout wrap>
                <v-flex xs12 pa-3 style="background-color: black">
                  <span style="font-size: 25px" class="heading">Applicant Details</span>
                </v-flex>

                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                

                <v-flex xs4 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Applicant Category
                  </span>
                  <br />
                  <span>{{ list.applicantCategory }}</span>
                </v-flex>

               


                <v-flex xs4 pl-3 pb-4 text-left v-if="list.applicantCategory === 'Students'">
                  <span class="subheading" style="font-size: 16px">
                    Student Category
                  </span>
                  <br />
                  <span>{{ list.studentCategory }}</span>
                </v-flex>

                <v-flex xs4 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Applicant
                  </span>
                  <br />
                  <span>{{ list.applicantname }}</span>
                </v-flex>

                <v-flex xs4 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                  Phone Number
                  </span>
                  <br />
                  <span>{{ list.userid.mobNumber }}</span>
                </v-flex>

                



                <v-flex xs12 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Total Participants
                  </span>
                  <br />
                  <span>{{ list.memberCount }}</span>
                </v-flex>

                <v-flex xs4 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Total Males
                  </span>
                  <br />
                  <span>{{ list.maleCount }}</span>
                </v-flex>
                <v-flex xs4 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Total Females
                  </span>
                  <br />
                  <span>{{ list.femaleCount }}</span>
                </v-flex>
                <v-flex xs4 pl-3 pb-4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Total Transgenders
                  </span>
                  <br />
                  <span>{{ list.transCount }}</span>
                </v-flex>


                <!-- <v-flex xs4 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Applicant Category
                  </span>
                  <br />
                  <span>{{ list.applicantCategory }}</span>
                </v-flex> -->
                <!-- <v-flex xs4 text-left >
                  <span class="subheading" style="font-size: 16px">v-if="list.applicantCategory === 'Students'"
                    Student Category
                  </span>
                  <br />
                  <span>{{ list.studentCategory }}</span>
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-7>
          <v-flex xs12>
            <v-card>
              <v-layout wrap>
                <v-flex xs12 pa-3 style="background-color: black">
                  <span style="font-size: 25px" class="heading">Institution Details</span>
                </v-flex>

                <v-flex xs12 pa-4>
                  <v-layout wrap>



                    <v-flex xs12 text-left pb-3>
                      <span class="subheading" style="font-size: 16px">
                        Type of Institution
                      </span>
                      <br />
                      <span>{{ list.instType }}</span>
                    </v-flex>

                    

                    <v-flex xs3 text-left>
                      <span class="subheading" style="font-size: 16px">
                        Institution/Organisation code
                      </span>
                      <br />
                      <span>{{ list.instCode }}</span>
                    </v-flex>

                    <v-flex xs3 text-left>
                      <span class="subheading" style="font-size: 16px">
                        Name of the Institution/Organisation
                      </span>
                      <br />
                      <span>{{ list.instName }}</span>
                    </v-flex>

                    <v-flex xs3 text-left>
                      <span class="subheading" style="font-size: 16px">
                        Address of the Institution/Organisation
                      </span>
                      <br />
                      <span>{{ list.instAddress }}</span>
                    </v-flex>

                    <v-flex xs3 text-left pt-4>
                      <span class="subheading" style="font-size: 16px">
                        Institution District
                      </span>
                      <br />
                      <span>{{ list.instDistrict }}</span>
                    </v-flex>
                    <v-flex xs3 text-left pt-4>
                      <span class="subheading" style="font-size: 16px">
                        Institution Phone Number

                        <br />
                      </span>
                      <span style="color: black">{{ list.instTeleNo }}</span>
                    </v-flex>
                    <v-flex xs3 text-left pt-4>
                      <span class="subheading" style="font-size: 16px">
                        Institution Mobile Number
                      </span>
                      <br />
                      <span style="color: black">{{ list.instMobileNo }}</span>
                    </v-flex>

                    <v-flex xs3 text-left pt-4>
                      <span class="subheading" style="font-size: 16px">
                        Institution Email
                      </span>
                      <br />
                      <span>{{ list.instEmail }}</span>
                    </v-flex>
                   
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-7>
          <v-flex xs12>
            <v-card>
              <v-layout wrap>
                <v-flex style="background-color: black" xs12 pa-3 class="heading">
                  <span style="font-size: 25px">Details of Head of Institution/Organization</span>
                </v-flex>

                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-layout wrap pa-3>
                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Name of Head of Institution/Organisation
                          </span>
                          <br />
                          <span>{{ list.instHeadName }}</span>
                        </v-flex>

                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Designation in Institute /Organisation
                          </span>
                          <br />
                          <span>{{ list.headDesignation }}</span>
                        </v-flex>

                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Phone Number
                          </span>
                          <br />
                          <span></span>
                        </v-flex>

                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            id card type
                          </span>
                          <br />
                          <span>{{ list.idCardType }}</span>
                        </v-flex>

                        <v-flex xs3 text-left>
                          <span class="subheading" style="font-size: 16px">
                            Id Card Number
                          </span>
                          <br />
                          <span>{{ list.idNo }}</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-7>
          <v-flex xs12>
            <v-card>
              <v-layout wrap>
                <v-flex xs12 pa-3 style="background-color: black">
                  <span style="font-size: 25px" class="heading">Group Leader/Teaching Staff Details
                  </span>
                </v-flex>
                <v-flex xs12 pa-5>
                  <v-card elevation="0" style="border: 1px solid black; border-radius: 0px">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs1 text-center pt-2> Name </v-flex>
                          <v-flex xs2 text-center pt-2> Designation </v-flex>
                          <v-flex xs2 text-center pt-2> Leader Gender </v-flex>
                          <v-flex xs2 text-center pt-2> Address </v-flex>
                          <v-flex xs1 text-center pt-2> Telephone </v-flex>
                          <v-flex xs2 text-center pt-2> Mobile Number </v-flex>
                          <v-flex xs2 text-center pt-2> Email </v-flex>
                          
                        </v-layout>
                      </v-flex>
                      <v-flex xs12> </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                    <v-layout pb-3 wrap v-for="(item, i) in leaderDetails" :key="i">
                      <v-flex xs1 text-center pt-2>
                        {{ item.leaderName }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.leaderAddress1 }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.leaderGender }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.leaderAddress2 }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ item.telephone }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.leaderPhone }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.leaderEmail }}
                      </v-flex>
                      
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-7>
          <v-flex xs12>
            <v-card>
              <v-layout wrap>
                <v-flex xs12 pa-3 style="background-color: black">
                  <span style="font-size: 25px" class="heading">
                    Member Details
                  </span>
                </v-flex>

                <v-flex xs12 pa-4>
                  <v-card elevation="0" style="border: 1px solid black; border-radius: 0px">
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs3 text-center pt-2> Name </v-flex>
                          <v-flex xs3 text-center pt-2> DOB </v-flex>
                          <v-flex xs3 text-center pt-2> Gender </v-flex>
                          <!-- <v-flex xs3 text-center pt-2>
                            Food Preferences
                          </v-flex> -->
                        </v-layout>
                      </v-flex>
                      <v-flex xs12> </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>

                    <v-layout wrap v-for="(item, i) in memberDetails" :key="i">
                      <v-flex xs3 text-center pb-2>
                        {{ item.memberName }}
                      </v-flex>
                      <v-flex xs3 text-center pb-2>
                        {{ item.memberDob }}
                      </v-flex>
                      <v-flex xs3 text-center pb-2>
                        {{ item.memberGender }}
                      </v-flex>
                      <!-- <v-flex xs3 text-center pb-2>
                        {{ item.foodPreference }}
                      </v-flex> -->
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-7 pb-10>
          <v-flex xs12>
            <v-card class="pa-4">
              <v-layout wrap justify-center>
                <v-flex xs3 pb-3 class="heading">
                  <span style="font-size: 20px">
                    Application Number: {{ list.applicationNo }}</span>
                </v-flex>
                <v-flex xs2 text-right pt-2 class="heading">
                  <v-btn @click="submitdialog = true" color="rgb(13, 154, 69)">
                    <span class="mainfont" style="color: white"> Approve </span>
                  </v-btn>
                </v-flex>
                <v-flex pt-2 text-right xs2 class="heading">
                  <v-btn @click="rejectdialog = true" color="red">
                    <span class="mainfont" style="color: white"> Reject </span>
                  </v-btn>
                </v-flex>
                <v-flex pt-2 text-right xs2>
                  <v-btn @click="$router.push('/records?id=' + list._id)" color="blue">
                    <span class="mainfont" style="color: white"> VIEW TIMELINE
                    </span>
                  </v-btn>
                </v-flex>
                <v-flex pt-3 text-right xs2>
                  <v-btn @click="forwardToUsers(), (deletedialogreturn = true)" plain class="text-capitalize"
                    color="green">
                    <v-icon color="green">mdi-share</v-icon>&nbsp;
                    <span>RETURN</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog :retain-focus="true" persistent v-model="submitdialog" max-width="800px">
            <v-card>
              <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want to Approve?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black" text @click="submitdialog = false" class="mainfont">Cancel</v-btn>
                <v-btn color="green" class="mainfont" text @click="approve()">Yes</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs3 pb-3>
            <v-btn color="primary" @click="goBack">
              <span style="text-transform: none; font-size: 15px; color: white;">
                GO BACK
              </span>
            </v-btn>
          </v-flex>
        </v-layout>



      </v-flex>
      <v-dialog :retain-focus="true" persistent v-model="deletedialogreturn" max-width="700px">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs8 text-left pt-5>
              <span class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want to Return?
              </span>
            </v-flex>
            <v-flex xs8 pt-6 pr-4 class="mainfont">
              <v-autocomplete outlined dense color="black" :items="usernames" v-model="selectedUsername"
                item-text="name" placeholder="Select a Role" style="font-family: mainfont" item-value="name"
                item-color="#FF1313" hide-details clearable class="custom-autocomplete">
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs8 pt-5 pr-4 class="mainfont">
              <v-text-field placeholder="Remarks" v-model="reason" dense class="mainfont" outlined color="black">
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="deletedialogreturn = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="forwardToUsers2()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog :retain-focus="true" persistent v-model="rejectdialog" max-width="600px">
        <v-card>
          <v-layout wrap justify-start>
            <v-flex xs12>
              <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want to Reject?
              </v-card-title>
            </v-flex>
            <v-flex xs12 pl-3 pr-3>
              <v-text-field type="text" dense color="#283e51" outlined background-color="white" v-model="reason"
                hide-details>
                <template v-slot:label>
                  <span class="custom-label-text mainfont" style="color: black; font-size: 14px">
                    Remarks<span style="color: red; font-size: 20px">*</span>
                  </span>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="rejectdialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="green" class="mainfont" text @click="reject()">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
// import CryptoJS from "crypto-js";
export default {
  components: {},
  data() {
    const today = new Date().toISOString().substr(0, 10);
    return {
      deletedialogreturn: false,
      usernames: [],
      selectedUsername: null,
      id: this.$route.query.id,

      memberdetails: [],
      memberDetails: [],
      submitdialog: false,
      leaderName: null,
      leaderAddress1: null,
      leaderAddress2: null,
      telephone: null,
      idCardType: null,
      leaderPhone: null,
      leaderGender: null,
      leaderEmail: null,
      district: null,
      campName:null,
      mobNumber:null,
      rejectdialog: false,

      instHeadName: null,
      headDesignation: null,
      idCardTypeidCardType: null,
      idNo: null,
      reason: null,

      startDate: today,
      showSnackBar: false,
      isChecked: false,
      appLoading: false,
      msg: "",
      expandSection: false,
      name: null,
      barlist: [],

      list: {},
      selectedDistrict: null,

      campDuration: "",
      menu1: false,
      male: null,
      memberCount: null,
      leaderdetails: [],
      leaderDetails: [],
      applicantCategory: "",
      studentCategory: "",
      studentCategories: [
        "UP students",
        "HS students",
        "HSS students",
        "College students",
      ],
      applicantname: null,
      applicantcategorys: [
        "Students",
        "Press Reporters",
        "LSG Members",
        "NGO youth/charitable organization",
      ],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    forwardToUsers(id) {
      this.getUsers(id);
      this.appids = id;
    },
    forwardToUsers2() {
      this.forward();
    },
    forward() {
  //     const encryptedId = this.$route.query.id;
  // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
  if (!this.selectedUsername) {
    this.msg ="Please select a user to forward to.";
    return;
}

      const selectedUser = this.usernames.find(
        (user) => user.name === this.selectedUsername
      );
      if (!selectedUser) {
        return;
      }

      axios({
        method: "post",
        url: "/forward/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          forwardto: selectedUser.userId,
          reason: this.reason,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push({ path: "/PendingApplicationsAdmin" });
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    getUsers() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        method: "post",
        url: "/cww/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,

        },
      })
        .then((response) => {
          this.usernames = [
            // {
            //   name: `${response.data.applicant.username} (User)`,
            //   role: "User",
            //   userId: response.data.applicant._id,
            // },
            {
              name: `${response.data.ministerial.username} (AdminministerialHead)`,
              role: "AdminministerialHead",
              userId: response.data.ministerial._id,
            },
            {
              name: `${response.data.divsion.username} (DFO)`,
              role: "DFO",
              userId: response.data.divsion._id,
            },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    goBack() {
      // Use the router to navigate back to the previous page
      this.$router.go(-1);
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },

    approve() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        method: "POST",
        url: "/admin/approval",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: "yes",
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.showSnackBar = true;

            this.msg = response.data.msg;
            this.submitdialog = false;
            this.$router.push({ path: "/PendingApplicationsAdmin" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.submitdialog = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    reject() {
      if (!this.reason) {

        this.msg = "Please provide a reason for rejection.";
        this.showSnackBar = true;
        return;
      }
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      axios({
        method: "POST",
        url: "/admin/approval",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: "no",

          reason: this.reason,
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.showSnackBar = true;

            this.msg = response.data.msg;
            this.rejectdialog = false;
            this.$router.push({ path: "/PendingApplicationsAdmin" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.rejectdialog = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    getList() {
      // const encryptedId = this.$route.query.id;
      // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            this.leaderDetails = this.list.leaderDetails;
            this.memberDetails = this.list.memberDetails;

            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>


<style>
.subheading {
  font-family: mainfont;
  color: rgb(13, 154, 69);
  text-transform: none;
  font-size: 17px;
}

.heading {
  font-family: mainfont;
  color: rgb(229, 111, 8);
  text-transform: none;
  font-size: 25px;
}
</style>